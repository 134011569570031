// import sum from 'lodash/sum';
// import uniq from 'lodash/uniq';
// import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
import { ServerConfiguration } from 'src/utils/serverConfig';
import stringEn from 'src/assets/stringEn';
// utils
// import axios from '../../utils/axios';

// ----------------------------------------------------------------------
const url = ServerConfiguration.APIServerUrl;

const initialState = {
  isLoading: false,
  error: null,
  lang: "en",
  stringSet: stringEn,
  salutation: [],
  addRegister: null,
};

const slice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // SET LANG
    setLang(state, action) {
      state.isLoading = false;
      state.lang = action.payload;
    },

    setStringSet(state, action) {
      state.isLoading = false;
      state.stringSet = action.payload;
    },

    viewSalutationSucess(state, action) {
      state.isLoading = false;
      state.salutation = action.payload;
    },

    userRegistrationSuccess(state, action) {
      state.isLoading = false;
      state.addRegister = action.payload;
    },

    clearRegistrationActionSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.addRegister = null;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setLang, setStringSet } = slice.actions;

// ----------------------------------------------------------------------

export function viewSalutation() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}General_ViewSalutation`)
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === "1") {
        dispatch(slice.actions.viewSalutationSucess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.viewSalutationSucess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function userRegistration(registerData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}User_Registration?SALUTATIONID=${registerData.salutation}&USERFULLNAME=${registerData.name}&USERCONTACT=${registerData.contact}&USEREMAIL=${registerData.email}&USERADDRESS=${registerData.address}&USERCHANNEL=${registerData.channel}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === "1") {
        dispatch(slice.actions.userRegistrationSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.userRegistrationSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearRegistrationAction() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearRegistrationActionSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}