import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
// @mui
import { List, Drawer, IconButton, Button, Stack, ToggleButton, ToggleButtonGroup, MenuItem, OutlinedInput } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// config
import useResponsive from 'src/hooks/useResponsive';
import { NAV } from 'src/config-global';
// components
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import stringEn from 'src/assets/stringEn';
import stringCn from 'src/assets/stringCn';
// import ReactCountryFlag from "react-country-flag"
//
import NavList from './NavList';
// redux
import { setStringSet, setLang } from '../../../../redux/slices/general';
import { useDispatch, useSelector } from '../../../../redux/store';
// import './NavMobile.scss'

// ----------------------------------------------------------------------



export default function NavMobile({ data }) {
  const { pathname } = useLocation();
  const isMdUp = useResponsive('up', 'md');


  const dispatch = useDispatch();
  const { lang, stringSet } = useSelector((datas) => datas.general);

  const [open, setOpen] = useState(false);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
  ];


  useEffect(() => {

    const stringObj = lang === "en" ? stringEn : stringCn
    dispatch(setStringSet(stringObj))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const COLORS = {
    primaryDark: "#115b4c",
    primaryLight: "#B6EDC8",
  };

  const IconButton1 = styled.label`
    background-color: ${COLORS.primaryLight};
    position: fixed;
    top: 6rem;
    right: 6rem;
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    cursor: pointer;
    z-index: 1000;
    box-shadow: 0 1rem 3rem rgba(182, 237, 200, 0.3);
    text-align: center;
  `;

  const Iconify1 = styled.span`
    position: relative;
    background-color: #c9b289;
    width: ${(props) => (props.isMdUp ? '1.5rem' : '1.0rem')};
    height: 2px;
    display: inline-block;
    transition: all 0.3s;
    box-shadow: 1px 1px 2px black;
    &::before,
    &::after {
      content: "";
      background-color: #c9b289;
      width:${(props) => (props.isMdUp ? '2.3rem' : '1.5rem')};
      height: 2px;
      box-shadow: 1px 1px 2px black;
      display: inline-block;
      position: absolute;
      left: 0;
      transition: all 0.3s;
    }
    &::before {
      top: ${(props) => (props.clicked ? "0" : "-0.6rem")};
      transform: ${(props) => (props.clicked ? "rotate(135deg)" : "rotate(0)")};
    }
    &::after {
      top: ${(props) => (props.clicked ? "0" : "0.6rem")};
      transform: ${(props) => (props.clicked ? "rotate(-135deg)" : "rotate(0)")};
    }
    .IconButton:hover &::before {
      top: ${(props) => (props.clicked ? "0" : "-1.0rem")};
    }
    .IconButton:hover &::after {
      top: ${(props) => (props.clicked ? "0" : "1.0rem")};
    }
  `;


  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const handleChange = (e) => {
    console.log(e.target.value)
    dispatch(setLang(e.target.value))
  };

  return (
    <>
      {/* <MenuLabel htmlFor="navi-toggle" onClick={handleClick}>
        <Icon clicked={click}>&nbsp;</Icon>
      </MenuLabel>  */}
      <Stack justifyContent="space-between" direction="row" alignItems="center">
        {isMdUp ?
          (
            <IconButton htmlFor="navi-toggle" className='IconButton' onClick={handleOpen} sx={{
              ml: 0, paddingLeft: 0, color: '#c9b289',
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent"
              }
            }}>
              <span style={{ fontSize: "0.6em", paddingRight: "0.8rem", textShadow: "1px 1px 2px black" }}>{`Menu  `}</span> <Iconify1 isMdUp />
            </IconButton>
          ) : (
            <IconButton htmlFor="navi-toggle" className='IconButton' onClick={handleOpen} sx={{
              ml: 0, paddingLeft: 0, color: '#c9b289', height: "10px",
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent"
              },
            }}>
              <Iconify1 />
            </IconButton>
          )}

        <Stack justifyContent="flex-end" sx={{ position: "absolute", right: "20px" }} flexDirection="row">
          {/* <ToggleButtonGroup
          size="small"
          color="primary"
          value={lang}
          onChange={handleChange}
          exclusive
        >
          <ToggleButton value="en" sx={{ fontSize: "15px" }}>EN</ToggleButton>
          <ToggleButton value="cn" sx={{ fontSize: "15px" }}>CN</ToggleButton>
        </ToggleButtonGroup> */}
          <Select
            value={lang}
            sx={{
              boxShadow: "none", textShadow: "1px 1px 2px black", color: '#c9b289',
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
              {
                border: 0,
              },
              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                border: 0,
              },

            }}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'Without label' }}
            renderValue={(selected) => {
              if (selected === "en") {
                return <em>English</em>;
              }
              if (selected === "cn") {
                return <em>中文</em>;
              }
              return <em>English</em>;
            }}>

            <MenuItem value="en"><em>English</em></MenuItem>
            <MenuItem value="cn"><em>中文</em></MenuItem>
          </Select>
        </Stack >
      </Stack >

      <Drawer
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            pb: 5,
            width: NAV.W_BASE,
            background: "#141F28",
            // bgcolor: 'linear-gradient(to right, #29474C, #507D8A)';
          },
        }}
      >
        <Scrollbar sx={{ width: '100%' }} justifyContent="center"
          alignItems="center" >

          {
            !isMdUp &&
            <Stack direction="row" justifyContent="flex-end" sx={{ mr: 5, mt: 5 }}>
              <Iconify icon="material-symbols:close" sx={{ color: "#c9b289" }} onClick={handleClose} />
            </Stack>
          }


          <Logo sx={{ mx: "auto", my: 3, pt: 3 }} Logowidth="50%" />


          {console.log(data)}
          <List component="nav" >
            {data.map((link) => (
              <NavList key={link.title} item={link} />
            ))}
          </List>



        </Scrollbar>
      </Drawer>
    </>
  );
}

NavMobile.propTypes = {
  data: PropTypes.array,
};
