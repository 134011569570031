import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import generalReducer from './slices/general';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

// export const productPersistConfig = {
//   key: 'product',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['sortBy', 'checkout'],
// };

// export const journalPersistConfig = {
//   key: 'journal',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: [''],
// };

// export const authorPersistConfig = {
//   key: 'author',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: [''],
// };

// export const categoryPersistConfig = {
//   key: 'category',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['categories'],
// };

// export const registerMerchantPersistConfig = {
//   key: 'registerMerchant',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['registerMerchant'],
// };

// export const chatPersistConfig = {
//   key: 'chat',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['chat'],
// };

const rootReducer = combineReducers({
  // mail: mailReducer,
  // chat: persistReducer(chatPersistConfig, chatReducer),
  // calendar: calendarReducer,
  // kanban: kanbanReducer,
  // product: persistReducer(productPersistConfig, productReducer),
  // user: userReducer,
  // profile: profileReducer,
  // // journal: persistReducer(journalPersistConfig, journalReducer),
  // // question: questionReducer,
  general: generalReducer,
  // order: orderReducer,
  // category: persistReducer(categoryPersistConfig, categoryReducer),
  // hotel: hotelReducer,
  // // registerMerchant: registerMerchantReducer,
  // tripPackage: tripPackageReducer,
  // event: eventReducer,
  // directory: directoryReducer,
  // registerMerchant: registerMerchantReducer,
});

export default rootReducer;
