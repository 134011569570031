import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// import SwiperCore, { EffectFade, Navigation, Pagination } from "swiper";
import { Fade, Slide } from "react-awesome-reveal";
// utils
import { bgBlur } from 'src/utils/cssStyles';
//
import Logo from '../logo';
import ProgressBar from '../progress-bar';

// install Swiper modules
// SwiperCore.use([Navigation, Pagination, EffectFade]);

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  ...bgBlur({
    // blur: 0,
    opacity: 1,
    color: theme.palette.background.default,
  }),
  top: 0,
  zIndex: 9999,
  position: 'fixed',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // background: 'linear-gradient(138deg, rgba(6,30,38,1) 19%, rgba(15,48,61,1) 47%, rgba(15,62,76,1) 64%, rgba(8,84,107,1) 84%, rgba(20,101,126,1) 95%)'
}));

// ----------------------------------------------------------------------

export default function LoadingScreen({ sx }) {
  return (
    <>
      <ProgressBar />

      <StyledRoot sx={sx}>
        <m.div
          transition={{
            duration: 5,
            repeatDelay: 3,
            repeat: Infinity,
          }}
        >
          <Fade damping={1} delay={500}>
            <img src="/assets/Allonge Logo_3.gif" alt="ErrorIMG" className="img-fluid" style={{ marginTop: "2vw",height: '40vh' }} />
          </Fade>
          {/* <Slide direction="left">
            <img src="/assets/02.png" alt="ErrorIMG" className="img-fluid" style={{ marginTop: "3vw" }} />
          </Slide>
          <Slide direction="right">
            <img src="/assets/03.png" alt="ErrorIMG" className="img-fluid" style={{ marginBottom: "10vw" }} />
          </Slide> */}
          {/* <Fade damping={2} delay={1000}>
            <img src="/assets/Allonge Logo 02.png" alt="ErrorIMG" className="img-fluid"  />
          </Fade> */}
        </m.div>

      </StyledRoot>

      {/* <Box sx={{ width: 1, height: '100vh', display: "flex", alignItems: "center" }} /> */}
    </>
  );
}

LoadingScreen.propTypes = {
  sx: PropTypes.object,
};
