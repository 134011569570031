export default {
  // ConcepptualPage
  ConceptTitleThe: "THE",
  ConceptTitleBnB: "AIR.B&B",
  ConceptTitleByThe: "BY THE",
  ConceptTitleGateway: "GATEWAY TO THE WORLD",
  ConceptBold: "ALLONGÉ @ KKIA",
  ConceptDesc: " is an epitome of hospitality meets travel wanderlust – that’s the magic of a well-positioned Air B&B. The characteristics and its locale at the zero-kilometer of Kota Kinabalu Aerotropolis - being the international and domestic entrance to Kota Kinabalu City – are perfectly set to greet and host the jetsetters, business travelers, holiday-makers, GIG professional and more.",
  ConceptQuote: "The 20th century was about cities building airports. The 21st century will be about airports building cities.",

  // ConcepptualPage2
  Concept2Title1: "UNWIND AT",
  Concept2TitleBold: " THE SANCTUARY BELOW THE WIND",
  Concept2Title2: "BEFORE YOUR NEXT JOURNEY",
  Concept2Desc1: "With the beautiful sunrise at Mount Kinabalu - a UNESCO World Heritage Site - in sight and the mesmerizing sunset on the horizon at the unobstructed South China Sea,",
  Concept2Desc2: "conjures up a sense of out-of-this-world tranquility and connectedness to the nature in the land below the wind.",

  // NewsletterCareer
  NewsTitle1: "A RARE ",
  NewsTitle2: "INVESTMENT OPPORTUNITY",
  NewsDesc: "offers investors with a range of commercial suites suitable for multiple uses – vacation service suite, office, short-to- mid-term rental investment property. The commercial suites come in 4 major categories – Studio, Single, Double and Dual-Key Premium – suitable for a wide range of investors looking to maximize returns on an investment property.",
  NewsDiscover: "DISCOVER YOUR INVESTMENT SUITE WITH",
  NewsLocation: "ALLONGÉ ",
  NewsTNC: "Disclaimer: All information and images are for reference only and are subject to change without notice. Unless expressly and specifically incorporated into the Sale and Purchase Agreement (SPA), nothing contained herein shall be considered a representation of the actual condition. Buyers must read the Sale and Purchase Agreement and its attachments before signing the agreement.",
  NewsButton: "REGISTER NOW",

  // RegisterInvestmentPage
  RegisterTitle: "REGISTER",
  RegisterFullName: "Full Name",
  RegisterContact: "Contact",
  RegisterEmail: "Email",
  RegisterAddress: "Address",
  RegisterSalutation: "Salutation",
  RegisterKnowMethod: "How did you come to know about ALLONGÉ @ KKIA?",
  RegisterKnowMethod1: "Social Media",
  RegisterKnowMethod2: "Newspaper",
  RegisterKnowMethod3: "Website",
  RegisterKnowMethod4: "Family & friends",
  RegisterKnowMethod5: "Advertisement",
  RegisterKnowMethod6: "Others",
  RegisterTerm1: "By submitting the form, I/we hereby acknowledge and understand that SALING DEVELOPMENT SDN BHD (Registration No. 877716-P) is collecting my/our personal data for the Purpose in our Personal Data Notice.",
  RegisterTerm2: "I have read and agreed with the ",
  RegisterTerm3: "Terms and Conditions.",

  // FloorPlanImage
  MainFloorPlan: "assets/floorPlan.png",
  TypeA1: "/assets/layout/a1.png",
  TypeA2: "/assets/layout/a2.png",
  TypeA3: "/assets/layout/a3.png",

  TypeB1: "/assets/layout/b1.png",
  TypeB2: "/assets/layout/b2.png",
  TypeB3: "/assets/layout/b3.png",
  TypeB4: "/assets/layout/b4.png",
  TypeB5: "/assets/layout/b5.png",

  TypeC1A: "/assets/layout/c1a.png",
  TypeC1B: "/assets/layout/c1b.png",
  TypeC2: "/assets/layout/c2.png",
  TypeC3: "/assets/layout/c3.png",
  TypeC4: "/assets/layout/c4.png",


  TypeD1: "/assets/layout/D1.png",
  TypeD2: "/assets/layout/D2.png",
  TypeD3: "/assets/layout/D3.png",
  TypeD4: "/assets/layout/D4.png",
  TypeD5: "/assets/layout/D5.png",
  TypeD6: "/assets/layout/D6.png",
  TypeD7: "/assets/layout/D7.png",
  TypeD8: "/assets/layout/D8.png",
  TypeD9: "/assets/layout/D9.png",

  // LocationImage
  GlobalLocation: "assets/global-location.jpg",
  location: "assets/allonge-location.png",


  // FacilitieImage
  facility: "assets/facility/facility.png",
  facility1: "assets/facility/facility_1.png",
  facility2: "assets/facility/facility_2.png",
  facility3: "assets/facility/facility_3.png",
  facility4: "assets/facility/facility_4.jpg",
  // facility5: "assets/facility/facility_5.jpg",
  // facility6: "assets/facility/facility_6.jpg",
  // facility7: "assets/facility/facility_7.jpg",
  // facility8: "assets/facility/facility_8.jpg",
  // facility9: "assets/facility/facility_9.jpg",
  // facility10: "assets/facility/facility_10.jpg",
  facility5: "assets/gallery/3.jpg",
  facility6: "assets/gallery/10.jpg",
  facility7: "assets/gallery/7.jpg",
  facility8: "assets/gallery/6.jpg",
  facility9: "assets/gallery/9.jpg",
  facility10: "assets/gallery/8.jpg",


  navConfig: [
    { title: 'Concept', path: '/' },
    { title: 'Gallery', path: '/gallery' },
    { title: 'Layout Plan', path: '/layout' },
    { title: 'Facilities', path: '/facilities' },
    { title: 'Location Map', path: '/location' },
    { title: 'Developer', path: '/developer' },
  ],

  gallery: [
    {
      id: 1,
      path: "/assets/gallery/1.jpg",
      alt: "ImageGallery",
    },
    {
      id: 2,
      path: "/assets/gallery/2.jpg",
      alt: "ImageGallery",
    },
    {
      id: 3,
      path: "/assets/gallery/3.jpg",
      alt: "ImageGallery",
    },
    {
      id: 4,
      path: "/assets/gallery/4.jpg",
      alt: "ImageGallery",
    },
    {
      id: 5,
      path: "/assets/gallery/5.jpg",
      alt: "ImageGallery",
    },
    {
      id: 6,
      path: "/assets/gallery/6.jpg",
      alt: "ImageGallery",
    },
    {
      id: 7,
      path: "/assets/gallery/7.jpg",
      alt: "ImageGallery",
    },
    {
      id: 8,
      path: "/assets/gallery/8.jpg",
      alt: "ImageGallery",
    },
    {
      id: 9,
      path: "/assets/gallery/9.jpg",
      alt: "ImageGallery",
    },
    {
      id: 10,
      path: "/assets/gallery/10.jpg",
      alt: "ImageGallery",
    },
    {
      id: 11,
      path: "/assets/gallery/11.jpg",
      alt: "ImageGallery",
    },
    {
      id: 12,
      path: "/assets/gallery/12.jpg",
      alt: "ImageGallery",
    },
    {
      id: 13,
      path: "/assets/gallery/13.jpg",
      alt: "ImageGallery",
    },
    {
      id: 14,
      path: "/assets/gallery/14.jpg",
      alt: "ImageGallery",
    },
  ]

}
