export default {
  ConceptTitleThe: "处於",
  ConceptTitleBnB: "世界之大门",
  ConceptTitleByThe: "的",
  ConceptTitleGateway: "AIR.B&B",
  ConceptBold: "雅蓉阁 @ KKIA",
  ConceptDesc: "是旅游业务给合旅行癖的优雅潮流缩影。 它的特点和地理位置 - 处於亚庇航空城零公里处 - 是亚庇市名副其实的国际和国内入口。是迎接和接待環遊世界者、商务旅客、度假者、GIG 专业人士等的绝佳选择。",
  ConceptQuote: "20世纪是城市建设机场, 21世纪是机场建设城市。",

  // ConcepptualPage2
  Concept2Title1: "在你的下一个旅程之前在",
  Concept2TitleBold: "“风下之城”的避风港",
  Concept2Title2: " 放松休歇",
  Concept2Desc1: "美丽的神山日出，迷人的南海日落，",
  Concept2Desc2: "让人联想到一种超凡脱俗的宁静感，以及风下之城与大自然是何等的紧密相连。",

  // NewsletterCareer
  NewsTitle1: "难得的 ",
  NewsTitle2: "投 资 机 会",
  NewsDesc: "为投资者提供一系列适合多种用途的商业套房--度假服务套房/办公空间;这些提供中短期租赁商业投资物业,适合寻求高回报率的大小投资者。",
  NewsDiscover: "选择您的投资於",
  NewsLocation: "雅蓉阁 ",
  NewsTNC: "免责声明：所有信息和图像仅供参考，如有更改，恕不另行通知。除非明确和具体地纳入买卖协议 （SPA），否则此处包含的任何内容均不得被视为要约实际状态的相同表示。 买家必须在签署买卖协议前阅读买卖协议及其附件。",
  NewsButton: "立即注册",

  // RegisterInvestmentPage
  RegisterTitle: "注册",
  RegisterFullName: "姓名",
  RegisterContact: "手机号",
  RegisterEmail: "邮箱",
  RegisterAddress: "地址",
  RegisterSalutation: "称谓",
  RegisterKnowMethod: "您是如何得知 雅蓉阁 @ KKIA 的？",
  RegisterKnowMethod1: "社交媒体",
  RegisterKnowMethod2: "报刊",
  RegisterKnowMethod3: "网站",
  RegisterKnowMethod4: "亲友",
  RegisterKnowMethod5: "广告",
  RegisterKnowMethod6: "其他",
  RegisterTerm1: " 提交表格后，我/我们特此承认并理解，SALING DEVELOPMENT SDN BHD（注册号：877716-P）正在收集我/我们的个人数据用于个人数据通知中的目的。",
  RegisterTerm2: " 我已阅读并同意",
  RegisterTerm3: "条款和条件。",

  // FloorPlanImage
  MainFloorPlan: "assets/floorPlan_cn.png",
  TypeA1: "/assets/layout/a1_cn.png",
  TypeA2: "/assets/layout/a2_cn.png",
  TypeA3: "/assets/layout/a3_cn.png",

  TypeB1: "/assets/layout/b1_cn.png",
  TypeB2: "/assets/layout/b2_cn.png",
  TypeB3: "/assets/layout/b3_cn.png",
  TypeB4: "/assets/layout/b4_cn.png",
  TypeB5: "/assets/layout/b5_cn.png",

  TypeC1A: "/assets/layout/c1a_cn.png",
  TypeC1B: "/assets/layout/c1b_cn.png",
  TypeC2: "/assets/layout/c2_cn.png",
  TypeC3: "/assets/layout/c3_cn.png",
  TypeC4: "/assets/layout/c4_cn.png",


  TypeD1: "/assets/layout/D1_cn.png",
  TypeD2: "/assets/layout/D2_cn.png",
  TypeD3: "/assets/layout/D3_cn.png",
  TypeD4: "/assets/layout/D4_cn.png",
  TypeD5: "/assets/layout/D5_cn.png",
  TypeD6: "/assets/layout/D6_cn.png",
  TypeD7: "/assets/layout/D7_cn.png",
  TypeD8: "/assets/layout/D8_cn.png",
  TypeD9: "/assets/layout/D9_cn.png",

  // LocationImage
  GlobalLocation: "assets/global-location_cn.png",
  location: "assets/allonge-location.png",

  // FacilitieImage
  facility: "assets/facility/facility_cn.png",
  facility1: "assets/facility/facility_1_cn.png",
  facility2: "assets/facility/facility_2_cn.png",
  facility3: "assets/facility/facility_3_cn.png",
  facility4: "assets/facility/facility_4_cn.jpg",
  facility5: "assets/facility/facility_5_cn.jpg",
  facility6: "assets/facility/facility_6_cn.jpg",
  facility7: "assets/facility/facility_7_cn.jpg",
  facility8: "assets/facility/facility_8_cn.jpg",
  facility9: "assets/facility/facility_9_cn.jpg",
  facility10: "assets/facility/facility_10_cn.jpg",

  navConfig: [
    { title: '概念', path: '/' },
    { title: '画廊', path: '/gallery' },
    { title: '布局规划', path: '/layout' },
    { title: '设施', path: '/facilities' },
    { title: '位置图', path: '/location' },
    { title: '开发商', path: '/developer' },
  ],

  gallery: [
    {
      id: 1,
      path: "/assets/gallery/1.jpg",
      alt: "ImageGallery",
    },
    {
      id: 2,
      path: "/assets/gallery/2.jpg",
      alt: "ImageGallery",
    },
    {
      id: 3,
      path: "/assets/gallery/3_cn.png",
      alt: "ImageGallery",
    },
    {
      id: 4,
      path: "/assets/gallery/4_cn.png",
      alt: "ImageGallery",
    },
    {
      id: 5,
      path: "/assets/gallery/5_cn.png",
      alt: "ImageGallery",
    },
    {
      id: 6,
      path: "/assets/gallery/6_cn.png",
      alt: "ImageGallery",
    },
    {
      id: 7,
      path: "/assets/gallery/7_cn.png",
      alt: "ImageGallery",
    },
    {
      id: 8,
      path: "/assets/gallery/8_cn.png",
      alt: "ImageGallery",
    },
    {
      id: 9,
      path: "/assets/gallery/9_cn.png",
      alt: "ImageGallery",
    },
    {
      id: 10,
      path: "/assets/gallery/10_cn.png",
      alt: "ImageGallery",
    },
    {
      id: 11,
      path: "/assets/gallery/11_cn.png",
      alt: "ImageGallery",
    },
    {
      id: 12,
      path: "/assets/gallery/12_cn.png",
      alt: "ImageGallery",
    },
    {
      id: 13,
      path: "/assets/gallery/13_cn.png",
      alt: "ImageGallery",
    },
    {
      id: 14,
      path: "/assets/gallery/14_cn.png",
      alt: "ImageGallery",
    },
  ]

}
